export function divideValueDependingOnUnit (value: any, unit: string) {
  if (unit === 'mm') return value
  if (unit === 'cm') return value / 100
  if (unit === 'm') return value / 1000
  return value
}

export function multiplyValueDependingOnUnit (value: any, unit: string) {
  if (unit === 'mm') return value
  if (unit === 'cm') return value * 100
  if (unit === 'm') return value * 1000
  return value
}
